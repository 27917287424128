$color-transparent: transparent;

$base-color: (
  'main': #7462e1,
  'text': #090212,
  'background': #fff,
  'success': #1b945c,
  'warning': #ffc453,
  'danger': #f53131,
);

$background-color: (
  'main/default': #fff,
  'main/disabled': #e9eaeb,
  'main/hover': #f1f2f3,
  'main/press': #edeeef,
  'main/subdued': #f6f7f7,
  'main/subdued2': #e0e0e0,
  'emphasize/default': #f5f4fd,
  'emphasize/hover': #cdc6f4,
  'emphasize/press': #9c8ee9,
  'success/default': #ebfbf4,
  'success/hover': #99ecc6,
  'success/press': #34da8c,
  'warning/default': #fffbf3,
  'warning/hover': #ffe9c1,
  'warning/press': #ffd483,
  'danger/default': #ffd6d6,
  'danger/hover': #fcb6b6,
  'danger/press': #f86d6d,
);

$border-color: (
  'default': #b2b5b8,
  'disabled': #d2d5d8,
  'emphasize': #7462e1,
  'success': #1b945c,
  'warning': #ffc453,
  'danger': #f53131,
);

$text-icon-color: (
  'default': #090212,
  'secondary': #666,
  'disabled': #babec3,
  'success': #1b945c,
  'warning': #ffc453,
  'danger': #f53131,
  'emphasize': #7462e1,
  'white': #fff,
  'link': #5173ff,
);

$interactive-color: (
  'main/default': #7462e1,
  'main/hover': #3c25c1,
  'main/press': #241674,
  'secondary/none': #fff,
  'secondary/hover': #f1f2f3,
  'secondary/press': #edeeef,
  'danger/default': #f53131,
  'danger/hover': #ca0a0a,
  'danger/press': #fcb6b6,
  'link/default': #5173ff,
  'link/hover': #0030f0,
  'link/press': #839cff,
);

// :root {
//   // Base
//   @each $text, $color in $base-color {
//     --ops-color--base-#{$text}: #{$color};
//   }

//   // Border
//   @each $text, $color in $border-color {
//     --ops-color--border-#{$text}: #{$color};
//   }

//   // Text
//   @each $text, $color in $text-icon-color {
//     --ops-color--text-#{$text}: #{$color};
//   }

//   // Background
//   @each $gp-text, $colors in $background-color {
//     @each $text, $color in $colors {
//       --ops-color--background-#{$gp-text}-#{$text}: #{$color};
//     }
//   }

//   // Interactive
//   @each $gp-text, $colors in $interactive-color {
//     @each $text, $color in $colors {
//       --ops-color--interactive-#{$gp-text}-#{$text}: #{$color};
//     }
//   }
// }

@mixin base-color($property, $code) {
  #{$property}: map-get($base-color, to-lower-case(#{$code}));
}

@mixin border-color($code) {
  border-color: map-get($border-color, to-lower-case(#{$code}));
}

@mixin text-icon-color($code) {
  color: map-get($text-icon-color, to-lower-case(#{$code}));
}

@mixin interactive-color($property, $code) {
  #{$property}: map-get($interactive-color, to-lower-case(#{$code}));
}

@mixin background-color($code) {
  background-color: map-get($background-color, to-lower-case(#{$code}));
}
