%CardIconAnim {
  #CardIcon {
    #Type {
      animation: cardType 1s ease-in-out;
    }

    #Number {
      animation: dot1Anim 0.3s ease-in-out;
    }

    #Number_2 {
      animation: dot2Anim 0.3s ease-in-out;
    }
  }

  #CardIcon {
    #Type,
    #Number,
    #Number_2 {
      transform-origin: center;
    }

    @keyframes cardType {
      0% {
        transform: rotateY(0);
      }

      100% {
        transform: rotateY(360deg);
      }
    }

    @keyframes dot1Anim {
      0% {
        opacity: 0;
        transform: translateX(45%);
      }

      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @keyframes dot2Anim {
      0% {
        opacity: 0;
        transform: translateX(-45%);
      }

      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

%FrameIconAnim {
  #FrameIcon {
    #Shape1,
    #Shape2,
    #Shape3,
    #Shape4,
    #Shape5,
    #Shape6,
    #Shape7 {
      opacity: 0;
    }

    #Shape1 {
      animation: shapeAnim 0.1s ease-in-out forwards;
    }
    #Shape2 {
      animation: shapeAnim 0.1s ease-in-out 0.1s forwards;
    }
    #Shape3 {
      animation: shapeAnim 0.1s ease-in-out 0.2s forwards;
    }
    #Shape4 {
      animation: shapeAnim 0.1s ease-in-out 0.3s forwards;
    }

    #Shape5 {
      animation: shapeAnim 0.1s ease-in-out 0.4s forwards;
    }

    #Shape6 {
      animation: shapeAnim 0.1s ease-in-out 0.5s forwards;
    }

    #Shape7 {
      animation: shapeAnim 0.1s ease-in-out 0.6s forwards;
    }
  }

  #FrameIcon {
    @keyframes shapeAnim {
      0% {
        opacity: 0;
        transform: translateY(-100%);
      }
      100% {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
}

%TableGameAnim {
  #TableGameIcon {
    #Vector {
      animation: appearAnim 0.3s ease-in-out forwards;
    }
    #Vector_2 {
      animation: appearAnim 0.3s ease-in-out 0.2s forwards;
    }
    #Vector_3 {
      animation: appearAnim 0.3s ease-in-out 0.1s forwards;
    }
    #Vector_4 {
      animation: appearAnim 0.3s ease-in-out 0.1s forwards;
    }
    #Vector_5 {
      animation: appearAnim 0.3s ease-in-out forwards;
    }
    #Vector_6 {
      animation: appearAnim 0.3s ease-in-out forwards;
    }
    #Vector_7 {
      animation: appearAnim 0.3s ease-in-out 0.1s forwards;
    }
    #Vector_8 {
      animation: appearAnim 0.3s ease-in-out 0.3s forwards;
    }
    #Vector_9 {
      animation: appearAnim 0.3s ease-in-out forwards;
    }
    #Vector_10 {
      animation: appearAnim 0.3s ease-in-out 0.2s forwards;
    }
  }

  @keyframes appearAnim {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
