@import '../../styles/variables.scss';

/**
   Sizes: name, padding-x, padding-y
*/
$sizes: (
  ('xl', 12px, 12px),
  ('lg', 12px, 12px),
  ('md', 12px, 8px),
);

.select {
  line-height: spacing(4.5);
  width: 100%;
  cursor: pointer;
}

@each $name, $padding-x, $padding-y in $sizes {
  .select-size-#{$name} {
    padding: #{$padding-y} #{$padding-x};
    padding-right: 0;
    line-height: 24px;
  }
}

.disabled {
  pointer-events: none;
}

.placeholder {
  @include text-icon-color('disabled');
  transition: color 0.1s ease;

  &:hover {
    @include text-icon-color('disabled');
  }
}

.icon {
  @include base-color('fill', 'text');
}

/**
  States: name, icon size.
*/
$icon-sizes: (('lg', 6), ('md', 6));
@each $name, $icon-size in $icon-sizes {
  .addon-size-#{$name} {
    .icon {
      width: spacing($icon-size);
      height: spacing($icon-size);
    }
  }
}

$label-sizes: (
  ('xl', $font-size-body1),
  ('lg', $font-size-body1),
  ('md', $font-size-body1),
);
@each $name, $size in $label-sizes {
  .label-size-#{$name} {
    font-size: $size;
    line-height: 24px;
    letter-spacing: 0.5px;
  }
}

.input.is-selected:not(.disabled) {
  @include background-color('main/default');
  @include border-color('emphasize');
  .icon,
  .icon svg {
    @include interactive-color('fill', 'main/default');
  }
}

.input.is-open {
  @include background-color('main/default');
  border: 2px solid;
  border-color: map-get($map: $border-color, $key: 'emphasize') !important;
  padding: 0;
}

// .input:not(.is-open) {
//   .label-text {
//     color: $color-blue-dark;
//   }
// }
