@import '../../styles/variables.scss';

.menuShow {
  white-space: nowrap;
  transition: all 0.3s linear;
  background-color: #fff !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;

  &:hover {
    border-color: map-get($map: $border-color, $key: 'emphasize') !important;
  }
}

.formDropdown {
  margin-top: 45px;
}

.displayTimeBox {
  display: flex;
  gap: 8px;
  align-items: center;

  .timeLocal {
    @include background-color('emphasize/default');
    border-radius: 100px;
    padding: 4px 12px;
  }
}
