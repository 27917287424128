@import './colors.scss';
@import './animIcon.scss';

@for $i from 1 to 56 {
  .m-#{$i} {
    margin: #{$i}px;
  }
  .mt-#{$i} {
    margin-top: #{$i}px;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }
  .ml-#{$i} {
    margin-left: #{$i}px;
  }
  .mr-#{$i} {
    margin-right: #{$i}px;
  }
  .mx-#{$i} {
    margin-left: #{$i}px;
    margin-right: #{$i}px;
  }
  .my-#{$i} {
    margin-top: #{$i}px;
    margin-bottom: #{$i}px;
  }
  .mt-#{$i}-rem {
    margin-top: #{$i}rem;
  }
  .mb-#{$i}-rem {
    margin-bottom: #{$i}rem;
  }

  .p-#{$i} {
    padding: #{$i}px;
  }
  .pt-#{$i} {
    padding-top: #{$i}px;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }
  .pl-#{$i} {
    padding-left: #{$i}px;
  }
  .pr-#{$i} {
    padding-right: #{$i}px;
  }
  .px-#{$i} {
    padding-left: #{$i}px;
    padding-right: #{$i}px;
  }
  .py-#{$i} {
    padding-top: #{$i}px;
    padding-bottom: #{$i}px;
  }
}

@for $i from 1 to 56 {
  .gap-#{$i} {
    gap: #{$i}px;
  }
}

#bubble {
  @for $i from 1 to 30 {
    .mb-#{$i} {
      margin-bottom: #{$i}px;
    }
    .p-#{$i} {
      padding: #{$i}px;
    }
  }
}

#main-drawer {
  .fe-custom-item {
    &:hover {
      @extend %CardIconAnim;
      @extend %FrameIconAnim;
      @extend %TableGameAnim;
    }
  }
}

.w-full {
  width: 100%;
}

.w-100px {
  width: 100px;
}

.w-50 {
  width: 50%;
}

.h-full {
  height: 100%;
}

.inline {
  display: inline;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.word-break-all {
  word-break: break-all;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.per_form_sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 10rem;
}

.pointer-events-none {
  pointer-events: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-end {
  text-align: end;
}

.grow {
  flex-grow: 1;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.text-underline {
  text-decoration: underline;
}

.layout-padding-x {
  padding-left: 24px;
  padding-right: 24px;
}

.layout-padding {
  padding: 16px 24px;
}
