@import '@core/uikit/build/src/styles/variables.scss';

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.imageFill {
  width: 100%;
  height: 100%;
}

.loading {
  @include text-icon-color('emphasize');
}
