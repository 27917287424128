.main {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 550px;
  max-height: 100vh;
  color: #031352;
  background: #fff;
  z-index: 9901;
  box-shadow: 0px 8px 16px rgb(3 19 82 / 16%);
  border-radius: 10px 0 0 0;

  .body {
    padding: 15px;
    box-shadow: 0 0 #f9f9f9;

    .title {
      margin-bottom: 7px;
      font-size: 14px;
      font-weight: bold;
    }

    .key {
      margin-bottom: 15px;
      .input {
        width: 100%;
        padding: 15px;
      }
    }

    .prefixInput {
      width: 100%;
      padding: 7px 15px;
    }

    .translator {
      overflow: auto;
      max-height: calc(100vh - 230px);
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          &:not(:last-child) {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #cdcdcd;
          }
        }
      }

      .list {
        .keyTrans {
          padding: 3px 7px;
          margin-bottom: 5px;
        }

        .value {
          background: #53965d;
          padding: 3px 7px;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }

    .translateButton {
      width: 100%;
      border: none;
      background-color: #53965d;
      color: #fff;
      padding: 10px 15px;
      cursor: pointer;
      border-radius: 3px;

      &:hover {
        background-color: #185822;
      }
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px 8px 3px 10px;
    background-color: red;
    color: #fff;
    cursor: pointer;
    width: 40px;

    &:hover {
      background-color: rgb(180, 15, 15);
    }
  }
}

.overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9900;
}
