@import '../../styles/variables.scss';

.main {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.icon_circle {
  & > svg > path {
    @include text-icon-color('default');
  }
}
