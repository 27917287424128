@import '../../styles/variables.scss';

.menuItem {
  cursor: pointer;

  &:not(.disabled):hover {
    @include background-color('emphasize/default');
  }

  + * .menuItem:not(:hover):not(.disabled):not(.activated) {
    color: $color-queen-blue;
  }
}
