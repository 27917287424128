@import '../../styles/variables.scss';

.wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 5px 12px;
  cursor: pointer;
  border: 1px solid map-get($map: $border-color, $key: 'default');
  border-radius: 8px;

  path {
    fill: map-get($map: $text-icon-color, $key: 'default') !important;
  }
}

@media (max-width: 1720px) {
  .textSearch {
    display: none;
  }
}
