@import '../../styles/variables.scss';

.wrapper {
  display: flex;
  min-height: calc(100vh - 72px); // 72px = height of header
  @include background-color('main/subdued');
}

.root {
  flex-grow: 1;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: 20px;
  left: 332px;
  z-index: 1205;
  margin-left: 12px;
}

.actions {
  & > *:not(:last-child) {
    margin-left: 8px;
  }
}
