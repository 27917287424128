@import '../../styles/variables.scss';

.list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  .list {
    padding-left: spacing(10);
    width: 100%;
  }
}

.direction-row {
  flex-direction: row;
}

.direction-column {
  flex-direction: column;
}

.direction-column-reverse {
  flex-direction: column-reverse;
}

.direction-row-reverse {
  flex-direction: row-reverse;
}
