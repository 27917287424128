@import '../../styles/variables.scss';

.step {
  flex: 1;
  height: 4px;
  border-radius: 999px;
  background-color: map-get($map: $border-color, $key: 'default');
  &:not(:last-child) {
    margin-right: 4px;
  }
}

.stepActive {
  background-color: map-get($map: $border-color, $key: 'emphasize');
}

.boxTitle {
  padding: 16px 24px;
  padding-bottom: 15px;
  border-bottom: 1px solid map-get($map: $border-color, $key: 'default');
}

.notShowBorderBottom {
  border-bottom: none;
  padding-bottom: 0;
}

.iconClose {
  padding: 0;
}
