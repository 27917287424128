@import '../../styles/variables.scss';

.root {
  background-color: rgb(9 2 18 / 65%);
  div:nth-child(3) > div {
    overflow: hidden;
  }
}

.container {
  width: 602px;
}

.wrapperContent {
  padding: 0px 24px 24px 60px;
}

.wrapperFooter {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px 24px;
  border-radius: 16px;
  @include background-color('main/subdued');
}
