@import '../../styles/variables.scss';

.dot {
  font-size: 18px;
  margin-right: 8px;
}

$types: (
  ('lock', $color-yellow-base),
  ('ban', $color-red-base),
  ('unlock', $color-persian-green),
  ('unban', $color-persian-green)
);

@each $name, $color in $types {
  .#{$name}Type {
    color: $color !important;
  }
}
