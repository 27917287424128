@import '../../styles/variables.scss';

$color-placeholder: map-get(
  $map: $text-icon-color,
  $key: 'disabled',
);
$color-border-emphasize: map-get(
  $map: $border-color,
  $key: 'emphasize',
);

/**
  States: name, color.
*/
$root-statuses: (
  (
    'primary',
    $color-persian-blue,
    $color-white,
    $color-text,
    $color-primary,
    $color-placeholder
  ),
  (
    'success',
    $color-green-base,
    $color-green-lightest,
    $color-green-base,
    $color-green-base,
    $color-green-base
  ),
  (
    'warning',
    $color-yellow-base,
    $color-yellow-lightest,
    $color-yellow-base,
    $color-yellow-base,
    $color-yellow-base
  ),
  (
    'error',
    $color-red-base,
    $color-red-lightest,
    $color-red-base,
    $color-red-base,
    $color-red-base
  )
);

.text-field {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & input:read-only {
    font-family: 'Inter';
    background-color: $color-gray-base;
    color: $color-gunmetal;
    border-color: $color-gray-base !important;
    &:hover {
      border-color: $color-gray-base !important;
    }
    &::placeholder {
      color: $color-cool-gray;
    }
    &:hover::placeholder {
      color: $color-cool-gray;
    }
  }

  input {
    width: 100% !important;
    font-family: 'Inter';
    padding: 19.5px 15px 19.5px 59px !important;
    height: 48px;
    border-radius: 8px !important;

    &:hover {
      border: 2px solid $color-border-emphasize !important;
      padding: 18.5px 14px 18.5px 58px !important;
    }

    &:focus {
      box-shadow: none !important;
      border: 2px solid $color-border-emphasize !important;
      padding: 18.5px 14px 18.5px 58px !important;
    }

    &::placeholder {
      color: $color-placeholder;
    }

    &:disabled {
      &::placeholder {
        color: $color-placeholder;
      }
    }
  }

  @each $name, $br-color, $bg-color, $text-color, $hover-br-color,
    $placeholder in $root-statuses
  {
    .status-#{$name}:not(.disabled) {
      outline: none !important;
      border: none !important;
      input {
        outline: none;
        background-color: $bg-color;
        color: $text-color;
        border-color: $br-color;
        &:focus {
          outline: none;
        }
        &:hover {
          outline: 1px solid $hover-br-color;
          border-color: transparent;
          ::placeholder {
            color: $placeholder;
          }
        }
        &::placeholder {
          color: $placeholder;
        }
        .adornment svg {
          color: $text-color;
        }
      }
    }
  }

  .label {
    @include text-icon-color('secondary');
  }

  .optional,
  .input,
  .note {
    color: inherit;
  }

  .label,
  .optional {
    margin-bottom: spacing(2);
  }

  .note {
    margin-top: spacing(2);
  }
}

.country-list {
  position: fixed !important;
}

.label {
  display: inline-flex;
  flex: 1 1 auto;
  justify-content: flex-start;
}

.optional {
  display: inline-flex;
  justify-content: flex-end;
}

.input {
  flex-basis: 100%;
}

.note {
  flex-basis: 100%;
}
