@import '../../styles/variables.scss';

.icon {
  fill: currentColor;
  color: currentColor;

  g {
    fill: inherit;
  }

  path:only-child {
    fill: inherit;
  }
}
