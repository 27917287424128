@import '@core/uikit/build/src/styles/variables.scss';

$color-border: map-get(
  $map: $border-color,
  $key: 'default',
);
$color-space-bg-active: map-get(
  $map: $background-color,
  $key: 'emphasize/default',
);
$color-space-bg-hover: map-get(
  $map: $background-color,
  $key: 'emphasize/hover',
);
$color-space-bg-press: map-get(
  $map: $background-color,
  $key: 'emphasize/press',
);

$header-height: 72px;
$spaces-box-width: 72px;
$padding-left-right: 24px;
$menu-box-width: 248px;

ul.menu {
  margin: 0;
  padding: 0;
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;
  gap: 8px;

  li {
    list-style: none;

    a {
      position: relative;
      width: 100%;
      border: none;
      display: flex;
      align-items: center;
      padding: 8px 28px 8px 12px;
      border-radius: 8px;
      @include text-icon-color('default');
      transition: all 0.3s linear;

      .icon {
        margin-right: 8px;
      }

      .text {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .arrow {
        position: absolute;
        right: 6px;
        top: 50%;
        transform: translateY(-50%);
      }

      &:hover {
        background-color: $color-space-bg-hover;
      }
      &:active {
        background-color: $color-space-bg-press;
      }
    }

    .subMenu {
      height: 0;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      transition: all 0.3s linear;

      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .subMenuActive {
      opacity: 1;
      height: auto;
      visibility: visible;
      overflow: visible;
      margin-top: 10px;
    }

    &.active {
      > a {
        .icon {
          @include text-icon-color('emphasize');
        }
        .text {
          font-weight: 600;
        }
      }
      &:not(.subMenuExpand) {
        > a {
          background-color: $color-space-bg-active;

          .text {
            @include text-icon-color('emphasize');
          }
        }
      }
    }
  }

  ul {
    padding-left: 24px;
    position: relative;
    margin-left: 6px;

    &::before {
      content: '';
      position: absolute;
      left: 15px;
      top: 0;
      height: 100%;
      width: 2px;
      background: $color-border;
    }
  }
}

.tooltip {
  background-color: #fff;
  color: #000;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  padding: 8px 16px;
  filter: drop-shadow(0px -4px 16px rgba(3, 19, 82, 0.16));
}

.tooltipArrow {
  &::before {
    background-color: #fff;
    border: 1px solid #e0e0e0;
  }
}

.tag {
  border-radius: 5px;
  border: 1px solid $color-purple-darker;
  padding: 0px 2px;
  font-size: 11px;
  color: $color-purple-darker;
  margin-left: 2px;
  position: absolute;
  top: 0;
}
