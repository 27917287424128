.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.menu-level-2 {
  padding-left: 40px !important;
}

.mt-300 {
  margin-top: 300px;
}

.kpi-tab-filter .MuiTab-textColorInherit.Mui-selected {
  background: #7462e1;
  color: #FFFFFF;
  border-radius: 4px;
}

.kpi-tab-filter .MuiTab-textColorInherit {
  opacity: 1;
}

.kpi-tab-filter button:hover {
  background: #CDC6F4;
  border-radius: 4px;
}

.List {
  border: 1px solid #d9dddd;
}

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ListItemEven {
  background-color: #f8f8f0;
}

.ListItemEven:hover,
.ListItemOdd:hover {
  background-color: #dedede;
}

.stepByStepTable {
  padding: 0 15px;
}

.stepByStepTable_Header {
  background: #cbcbcb;
  padding-top: 15px;
  padding-bottom: 15px;
}