@import '../../styles/variables.scss';

.otherUnit {
  display: inline-block;

  > svg {
    width: 24px;
    height: 24px;
    display: inline-block;
  }
}

.danger {
  @include text-icon-color('danger');
}

.success {
  @include text-icon-color('success');
}

.displayBlock {
  display: inline-block;
}
