@import '../../styles/variables.scss';

.root {
  cursor: pointer;
  position: relative;
  align-items: center;
  transition: all 0.25s ease;

  &:not(.disabled):hover {
    @include background-color('emphasize/default');
  }

  + * .menuItem:not(:hover):not(.disabled):not(.activated) {
    color: $color-cool-gray;
  }

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
    @include text-icon-color('disabled');
  }

  &:not(.disabled):not(.intermediate).checked {
    .icon {
      @include text-icon-color('emphasize');
    }
  }

  &.disabled.checked {
    .icon {
      @include text-icon-color('disabled');
    }
  }

  .icon {
    width: 2.4rem;
    height: 2.4rem;
    @include text-icon-color('emphasize');
    line-height: inherit;
    flex-shrink: 0;
  }
}

.content {
  margin-left: spacing(0);
}

.input {
  display: none;
}
