@import '../../styles/variables.scss';

.dropdownList {
  align-items: center;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  min-width: 220px;

  svg {
    fill: map-get($map: $text-icon-color, $key: 'default') !important;
  }

  &:hover,
  &:focus-within {
    border-color: map-get($map: $text-icon-color, $key: 'emphasize') !important;
  }
}

.customChildren {
  & > div,
  button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
