@import '@core/uikit/build/src/styles/variables.scss';

.containerBanking {
  padding: 24px;
  @include background-color('main/default');
  border: 1px solid map-get($map: $border-color, $key: 'default');
  border-radius: 16px;

  .divider {
    border: 1px solid map-get($map: $border-color, $key: 'default');
  }
}
