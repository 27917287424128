@import '../../styles/opsColors.scss';

.fenceBox {
  display: flex;

  .item {
    position: relative;
    padding-right: 24px;
    &::after {
      content: '';
      height: 100%;
      width: 2px;
      background-color: map-get($border-color, 'default');
      position: absolute;
      top: 0;
      right: 0;
      margin: 0px 12px;
    }

    &:last-child {
      padding: 0;
      &::after {
        content: none;
      }
    }
  }

  .title {
    font-size: 12px;
    font-family: Inter;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 1px;
    @include text-icon-color('secondary');
  }

  .value {
    font-size: 20px;
    font-family: Inter;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.25px;
    @include text-icon-color('default');
  }
}
