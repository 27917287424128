@import '../../styles/variables.scss';
$root-statuses: (
  ('primary', map-get($map: $text-icon-color, $key: "default")),
  ('success', map-get($map: $text-icon-color, $key: "success")),
  ('warning', map-get($map: $text-icon-color, $key: "warning")),
  ('error', map-get($map: $text-icon-color, $key: "danger"))
);

.text-field {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @each $name, $textColor in $root-statuses {
    &:not(.disabled).status-#{$name} {
      color: $textColor;
    }
  }

  .label {
    @include text-icon-color("Secondary");
    font-weight: 600;
  }

  .optional,
  .input,
  .note {
    color: inherit;
  }

  .label,
  .optional {
    margin-bottom: spacing(2);
  }

  .note {
    margin-top: spacing(2);
  }
}

.password {
  .icon {
    @include text-icon-color("default");
    &.success-icon {
      @include text-icon-color("success");
    }
  }
}

.errorNotDisable {
  // background-color: $color-red-lightest;
  // color: $color-red-base;
  // border-color: $color-red-base;
  @include background-color("Danger/Default");
  @include text-icon-color("Danger");
  @include border-color("Danger");
  padding: 1px;
}
