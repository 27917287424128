@import '@core/uikit/build/src/styles/variables.scss';

.MuiDialogPaper {
  max-width: 1584px !important;
  height: calc(100% - 64px) !important;
  max-height: 975px !important;
  border-radius: 16px;
}
.MuiDialogContentRoot {
  padding: 24px !important;
}
.MuiDialogContainer {
  background: rgba(39, 47, 61, 0.16) !important;
  backdrop-filter: blur(8px) !important;
}

.openButton {
  border: none;
  background-color: transparent;
  margin-right: 8px;
}

.openButton.imageButtonWrapper {
  margin-top: 6px;
  margin-bottom: 5px;
}

.imageButtonWrapper {
  height: 40px;
  width: 100px;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
}

.imageFileName {
  white-space: nowrap;
}

.imageButton {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left;
}

.imageContainer {
  height: 100%;
  overflow: hidden;
  display: flex;

  border: 1px solid map-get($map: $border-color, $key: 'default');
  border-radius: 8px;
}

.fileName {
  position: relative;
  padding: 16px 24px;
  border-bottom: 1px solid #c9cccf;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
