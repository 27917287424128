$color-persian-blue: #3896e3;
$color-persian-blue-darker: #1c76c1;
$color-persian-blue-darkest: #14568c;
$color-persian-blue-lighter: #8cc2ef;
$color-persian-blue-lightest: #e2f0fb;
$color-persian-green: #1b945c;
$color-persian-green-darker: #136941;
$color-persian-green-darkest: #0c3f27;
$color-persian-green-lighter: #71e5af;
$color-persian-green-lightest: #ebfbf4;
$color-queen-blue: #556785;
$color-blue-dark: #031352;
$color-gunmetal: #272f3d;
$color-cool-gray: #7c84a3;
$color-ghost-white: #fafbff;
$color-red-base: #f53131;
$color-red-darker: #cc1818;
$color-red-darkest: #a30707;
$color-red-lighter: #ff8585;
$color-red-lightest: #ffd6d6;
$color-yellow-base: #ffd52f;
$color-yellow-darker: #ebc017;
$color-yellow-darkest: #bd9804;
$color-yellow-lighter: #ffe682;
$color-yellow-lightest: #fffbeb;
$color-green-base: #29e08b;
$color-green-darker: #1b945c;
$color-green-darkest: #105736;
$color-green-lighter: #7af0b9;
$color-green-lightest: #d6ffec;
$color-purple-base: #5d1be0;
$color-purple-darker: #410aad;
$color-purple-darkest: #260070;
$color-purple-lighter: #9d73f0;
$color-purple-lightest: #e4d6ff;
$color-blue-violet: #873ee0;
$color-gray-base: #f5f5f5;
$color-gray-darker: #e0e0e0;
$color-gray-darkest: #cccccc;
$color-primary: $color-persian-green;
$color-white: #fff;
$color-black: #000;
$color-text: $color-blue-dark;
$color-error: $color-red-base;
$color-warning: $color-yellow-base;
$color-sub: $color-queen-blue;
$color-disabled: $color-gray-base;
$color-invert: $color-ghost-white;
$color-link: #3896e3;
$color-title-sub: $color-cool-gray;
$color-secondary: $color-gray-base;
$color-background-base: #f8f9fb;
$color-transparent: transparent;
$color-placeholder: $color-gray-darker;
$color-sidebar-hover: #1c222c;
$color-button: #67677a;
