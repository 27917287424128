@import '../../styles/variables.scss';

.customDialog {
  max-width: 630px !important;

  background-color: map-get($background-color, 'emphasize/default') !important;
}

.wrapper {
  width: 600px;
  padding: 15px;
}

.searchBox {
  margin-bottom: 18px;
  position: sticky;
  top: 15px;
  z-index: 10;

  input {
    font-size: 24px;
    padding: 10px 50px 10px 15px;
    width: 100%;
    border: 2px solid;
    @include border-color('default');
    border-radius: 4px;

    &:focus {
      outline: none;
      @include border-color('emphasize');
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
}

.emphasize {
  @include text-icon-color('emphasize');
}

.resultBox {
  .resultText {
    font-size: 14px;
    margin-bottom: 12px;
    @include text-icon-color('emphasize');
    font-weight: bold;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style: none;

      a {
        width: 100%;
        display: flex;
        align-items: center;
        background: #fff;
        margin-bottom: 7px;
        box-shadow: 0 1px 3px 0 #d4d9e1;
        border-radius: 4px;
        padding: 9px 15px;
        border: 2px solid #fff;
        position: relative;
        transition: all 0.3s ease-in-out;

        &[data-selected='true'] {
          @include border-color('emphasize');
          .enterIcon {
            opacity: 1;
          }
        }

        > svg {
          margin-right: 15px;
          @include text-icon-color('emphasize');
        }

        .enterIcon {
          opacity: 0;
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          transition: all 0.3s ease-in-out;
          > svg {
            @include text-icon-color('emphasize');
          }
        }
      }
    }
  }
}

.guide {
  padding: 7px 15px;
  background-color: #fff;
  box-shadow: 0 0 6px 2px #e7e7e7;
  display: flex;
  gap: 18px;
  align-items: center;
  position: sticky;
  bottom: 0px;
  z-index: 4;
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .icon {
    border-bottom: 2px solid;
    @include border-color('emphasize');
    padding: 0px 4px;
    @include text-icon-color('emphasize');
    height: 27px;
    width: 32px;
  }

  .upAndDown {
    position: relative;
    svg:first-child {
      position: absolute;
      top: 0;
      left: calc(50% - 5px);
      transform: translateX(-50%) rotate(-90deg);
    }
    svg:last-child {
      position: absolute;
      top: 0;
      left: calc(50% + 4px);
      transform: translateX(-50%) rotate(90deg);
    }
  }

  .esc {
    width: 40px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
