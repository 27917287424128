/**
  States: name, border-color, background-color, color, hover-border-color, placeholder, focus-border-color
*/
$root-statuses: (
  (
    'primary',
    map-get(
      $map: $border-color,
      $key: 'default',
    ),
    map-get(
      $map: $background-color,
      $key: 'main/default',
    ),
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    map-get(
      $map: $border-color,
      $key: 'default',
    ),
    map-get(
      $map: $text-icon-color,
      $key: 'disabled',
    ),
    map-get(
      $map: $border-color,
      $key: 'emphasize',
    )
  ),
  (
    'success',
    $color-green-base,
    $color-green-lightest,
    $color-green-base,
    $color-green-base,
    $color-green-base
  ),
  (
    'warning',
    $color-yellow-base,
    $color-yellow-lightest,
    $color-yellow-base,
    $color-yellow-base,
    $color-yellow-base
  ),
  (
    'error',
    $color-red-base,
    $color-red-lightest,
    $color-red-base,
    $color-red-base,
    $color-red-base
  )
);

@each $name, $br-color, $bg-color, $text-color, $hover-br-color, $placeholder,
  $focus-br-color in $root-statuses
{
  .status-#{$name}:not(.disabled) {
    background-color: $bg-color;
    color: $text-color;
    border-color: $br-color;
    padding: 1px;

    &:hover {
      border: 2px solid $hover-br-color;
      padding: 0px;
      color: $text-color;
      ::placeholder {
        color: $placeholder;
      }
    }
    &:focus-within,
    &:active {
      border: 2px solid $focus-br-color;
      padding: 0px;
      color: $text-color;
      ::placeholder {
        color: $placeholder;
      }
    }
    ::placeholder {
      color: $placeholder;
    }
    .adornment svg {
      color: $text-color;
    }
  }
}
