@import '../../styles/variables.scss';

$table-header-bg-color: map-get(
  $map: $background-color,
  $key: 'main/subdued2',
);
$table-body-bg-color: map-get(
  $map: $background-color,
  $key: 'main/default',
);
$table-footer-bg-color: map-get(
  $map: $background-color,
  $key: 'main/subdued',
);
$border-color: map-get(
  $map: $border-color,
  $key: 'default',
);
$text-header-color: map-get(
  $map: $text-icon-color,
  $key: 'default',
);
$text-body-color: map-get(
  $map: $text-icon-color,
  $key: 'default',
);

$row-bg-hover: map-get(
  $map: $background-color,
  $key: 'main/hover',
);

$row-selected-bg-color: hsl(206, 76%, 94%);
$row-selected-hover-bg-color: hsl(206, 76%, 92%);

$row-highlight-bg-color: map-get(
  $map: $background-color,
  $key: 'emphasize/default',
);
$row-highlight-hover-bg-color: map-get(
  $map: $background-color,
  $key: 'emphasize/hover',
);

$row-expanded-bg-color: map-get(
  $map: $background-color,
  $key: 'emphasize/press',
);
$row-expanded-hover-bg-color: map-get(
  $map: $background-color,
  $key: 'emphasize/press',
);
$row-expanded-text-color: white;
$row-sub-component-bg-color: $color-persian-green-lightest;

@mixin row-border {
  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}

.checkbox {
  padding-left: 16px !important; // Override default table cell padding
  padding-right: 16px !important;
}

.sticky-checkbox {
  left: 0;
}

.table-outer-wrapper {
  display: flex;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100%;
  overflow: hidden;
  border: 1px solid $border-color;
  border-left: none;
  border-right: none;
}

.show-border-sides {
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
}

.show-border-radius {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.table-outer-wrapper-loading {
  position: relative;

  .table-inner-wrapper.table-loading {
    position: static;
  }
}

.table-inner-wrapper {
  position: relative;
  overflow-x: auto;
  max-height: calc(100vh - var(--ex-height-table));
  width: 100%;
  background: $table-body-bg-color;
  transition: all 0.3s linear;

  &.withoutScroll {
    max-height: inherit;
  }

  &.table-loading {
    position: relative;
    overflow: hidden;
    cursor: progress;
    @include text-icon-color('emphasize');
    & td {
      opacity: 0.4;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      z-index: 1;
    }
    .loading {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

table.root {
  display: table;
  width: 100%;
  background-color: $table-body-bg-color;
  border-spacing: 0;
  border-collapse: separate;
  padding: 0;
  // line-height: 1.33;
  overflow: auto;
}

table.table-layout-auto {
  table-layout: auto;
}

table.table-overflow-x {
  width: max-content;
  min-width: 100%;
}

.table-sticky {
  isolation: isolate;

  tr th,
  tr td {
    background: $table-body-bg-color;
    z-index: 1;
  }
}

.header {
  position: sticky;
  top: 0;
  left: 0;

  display: table-header-group;
  z-index: 3;
  tr {
    color: inherit;
    display: table-row;
    vertical-align: middle;
    outline: 0;
    border-bottom: 1px solid $border-color;

    th {
      background-color: $table-header-bg-color;
    }
  }
  .cell {
    font-family: $font-family-body;
    font-size: $font-size-body1;
    font-weight: $font-weight-bold;
    color: $text-header-color;
    display: table-cell;
    vertical-align: inherit;
    position: sticky;
    top: 0px;
    z-index: 1;
    padding: 0 24px;
    padding-right: 24px;
    line-height: 22px;
    @include row-border;

    // &:last-child {
    //   border-right: none;
    // }

    &[colspan] {
      text-align: center;
    }

    .headerText {
      padding: 12px 0;
      display: inline-flex;
    }
  }
}

$aligns: ('left', 'center', 'right');
@each $align in $aligns {
  .align-#{$align} {
    text-align: #{$align};
  }
}

.sticky-cell {
  position: sticky !important;
  z-index: 2 !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.stickyRight {
  border-left: 1px solid $border-color;
}

.body {
  background: $table-body-bg-color;
  .cell,
  .cell-empty {
    font-size: $font-size-body2;
    font-weight: $font-weight-regular;
    color: $text-body-color;

    @include row-border;

    &:last-child {
      border-right: none;
    }
  }
  .cell-empty {
    text-align: center;
    color: $text-body-color;
  }
}

.body {
  .row {
    transition: all 0.25s ease;

    &:not(:last-child) {
      @include row-border;
    }
    &:active {
      td {
        background: $row-bg-hover; /* Override column color */
      }
    }
    &.can-click {
      cursor: pointer;
    }
    &.active {
      td {
        background: $row-bg-hover; /* Override column color */
      }
    }
    &:hover {
      td {
        background: $row-bg-hover; /* Override column color */
      }
    }

    &.highlight-row {
      td {
        background: $row-highlight-bg-color !important; /* Override column color */
      }

      &:hover {
        td {
          background: $row-highlight-hover-bg-color !important; /* Override column color */
        }
      }
    }

    &.selected-row {
      td {
        background: $row-selected-bg-color !important; /* Override column color */
      }

      &:hover {
        td {
          background: $row-selected-hover-bg-color !important; /* Override column color */
        }
      }
    }

    &.expanded-row {
      td {
        background: $row-expanded-bg-color !important; /* Override column color */
        color: $row-expanded-text-color !important; /* Override text color */

        & * {
          color: $row-expanded-text-color !important; /* Override custom text color */
        }
      }

      &:hover {
        td {
          background: $row-expanded-hover-bg-color !important; /* Override column color */
        }
      }
    }
  }

  .row-sub-component {
    background-color: $row-sub-component-bg-color;
  }
}

.cell {
  display: table-cell;
  padding: 8px 24px;
  line-height: 22px;

  & > span {
    display: inline-block;
  }
}

.cell-empty {
  padding: 1.6rem 2.4rem 1.5rem;
}

.row-empty {
  padding: 1.6rem 2.4rem 1.5rem;
  min-height: 52px;
  background-color: $table-body-bg-color;
}

.empty-loading {
  position: relative;
}

.footer {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 2;

  .cell {
    font-family: $font-family-body;
    font-size: $font-size-body1;
    font-weight: $font-weight-bold;
    color: $text-body-color;
    padding-top: 12px;
    padding-bottom: 12px;
    background: $table-footer-bg-color !important; /* Override column color */
  }

  tr {
    td {
      border-top: 1px solid $border-color;
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
    }
  }
}

.footerWithPagination {
  bottom: 60px;
}

.pagination {
  border-top: 1px solid $border-color;
  padding: 8px 24px;
  background: $table-body-bg-color;
  ul {
    margin-bottom: 0;
  }
}
