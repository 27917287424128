@import '@core/uikit/build/src/styles/variables.scss';

.wrapperTable {
  padding: 24px 0px 0px;
  @include background-color('main/default');
  border: 1px solid map-get($map: $border-color, $key: 'default');
  border-radius: 16px;
}

.wrapperTabs {
  flex-grow: 1;
  background-color: transparent;

  > div {
    div {
      padding: '0px';
      margin-top: '16px';
    }
  }

  .tabs {
    @include text-icon-color('emphasize');
    width: fit-content;
    border: 1px solid map-get($border-color, 'emphasize');
    height: 44px;
    padding: 4px;
    background: #fff !important;
    box-shadow: none;
    border-radius: 8px;

    & > div > div > span {
      display: none;
    }
  }

  .tab1 {
    min-width: 98px;
    height: 34px;
    min-height: 34px;
    text-transform: capitalize;
  }

  .tab2 {
    min-width: 105px;
    height: 34px;
    min-height: 34px;
    text-transform: capitalize;
  }
}
