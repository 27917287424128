@import '@core/uikit/build/src/styles/variables.scss';

.menuShow {
  white-space: nowrap;
  transition: all 0.3s linear;
  background-color: #fff !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;

  svg {
    margin-right: 8px;
  }

  .expand {
    margin: 0;
  }

  &:hover {
    border-color: map-get($map: $border-color, $key: 'emphasize') !important;
  }
}

.menuProfile {
  margin-top: 45px;
}

.pepperCustom {
  width: 235px;

  ul {
    li {
      padding: 8px 16px;

      &:hover {
        @include background-color('emphasize/hover');
      }
      &:active {
        @include background-color('emphasize/press');
      }
    }
  }
}

.divider {
  margin: 8px 0;
}

.danger {
  span {
    @include text-icon-color('danger');
  }
}

@media (max-width: 1445px) {
  .profileName {
    display: none;
  }
}
