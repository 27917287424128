@import '../../styles/variables.scss';

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn {
  outline: 0;
  box-sizing: border-box;
  font-weight: bold;
  border: 1px solid transparent;
  border-radius: $radius-bold;
  transition: background 0.25s ease, color 0.25s ease;
  color: inherit;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
}

/*
  values: name, font size, line height, font weight, padding-y, padding-x, letter-spacing
*/
$text-sizes: (
  ('lg', 20px, 28px, 500, 10px, 20px, 0.25px),
  ('md', 16px, 24px, 600, 8px, 16px, 0.5px),
  ('sm', 14px, 22px, 600, 5px, 12px, 0.75px)
);
@each $name, $font-size, $line-height, $font-weight, $padding-y, $padding-x,
  $letter-spacing in $text-sizes
{
  :not(.use-icon).size-#{$name} {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
    letter-spacing: $letter-spacing;
  }
}

.padding-text-loading {
  padding: 0px !important;
}

/*
  values: name, padding, icon size
*/
$icon-sizes: (
  ('lg', 32px, $font-size-14, 1.38, $font-weight-bold, 8px),
  ('md', 24px, $font-size-14, 1.29, $font-weight-bold, 8px),
  ('sm', 16px, $font-size-14, 1.29, $font-weight-bold, 8px)
);
@each $name, $size, $font-size, $line-height, $font-weight,
  $padding in $icon-sizes
{
  .use-icon.size-#{$name} {
    padding: $padding;
    .icon {
      height: $size;
      width: $size;
    }
    &.loading {
      cursor: progress;
      svg {
        width: $size;
        height: $size;
        animation: rotating 1.5s linear infinite;
        path:first-child {
          fill: map-get($map: $text-icon-color, $key: 'disabled');
        }
        path:last-child {
          fill: map-get($map: $text-icon-color, $key: 'disabled');
        }
      }
    }
  }
}

/*
  values: name, icon-size, spacing-right
*/
$start-icon-sizes: (('lg', 24px, 10px), ('md', 24px, 8px), ('sm', 20px, 6px));
@each $name, $icon-size, $spacing-right in $start-icon-sizes {
  .size-#{$name} {
    .start-icon {
      font-size: $icon-size;
      margin-right: $spacing-right;

      svg {
        width: $icon-size;
        height: $icon-size;
      }
    }
  }
}

$variants: (
  // name, text color, border-color, bg color, bg hover, bg pressed, bg-disabled, color hover
  (
      'primary',
      map-get(
        $map: $text-icon-color,
        $key: 'white',
      ),
      $color-transparent,
      map-get(
        $map: $interactive-color,
        $key: 'main/default',
      ),
      map-get(
        $map: $interactive-color,
        $key: 'main/hover',
      ),
      map-get(
        $map: $interactive-color,
        $key: 'main/press',
      ),
      map-get(
        $map: $background-color,
        $key: 'main/disabled',
      ),
      map-get(
        $map: $text-icon-color,
        $key: 'white',
      )
    ),
  (
    'secondary',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    map-get(
      $map: $border-color,
      $key: 'default',
    ),
    map-get(
      $map: $background-color,
      $key: 'main/default',
    ),
    map-get(
      $map: $interactive-color,
      $key: 'secondary/hover',
    ),
    map-get(
      $map: $interactive-color,
      $key: 'secondary/press',
    ),
    map-get(
      $map: $background-color,
      $key: 'main/disabled',
    ),
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    )
  ),
  (
    'danger',
    map-get(
      $map: $text-icon-color,
      $key: 'white',
    ),
    $color-transparent,
    map-get(
      $map: $interactive-color,
      $key: 'danger/default',
    ),
    map-get(
      $map: $interactive-color,
      $key: 'danger/hover',
    ),
    map-get(
      $map: $interactive-color,
      $key: 'danger/press',
    ),
    map-get(
      $map: $background-color,
      $key: 'main/disabled',
    ),
    map-get(
      $map: $text-icon-color,
      $key: 'white',
    )
  ),
  (
    'ghost',
    map-get(
      $map: $text-icon-color,
      $key: 'emphasize',
    ),
    map-get(
      $map: $border-color,
      $key: 'emphasize',
    ),
    map-get(
      $map: $interactive-color,
      $key: 'secondary/none',
    ),
    map-get(
      $map: $interactive-color,
      $key: 'main/hover',
    ),
    map-get(
      $map: $interactive-color,
      $key: 'main/press',
    ),
    map-get(
      $map: $background-color,
      $key: 'main/default',
    ),
    map-get(
      $map: $text-icon-color,
      $key: 'white',
    )
  ),
  (
    'select',
    $color-white,
    $color-transparent,
    $color-cool-gray,
    $color-cool-gray,
    $color-cool-gray,
    $color-transparent
  ),
  (
    'outline',
    map-get(
      $map: $text-icon-color,
      $key: 'emphasize',
    ),
    map-get(
      $map: $border-color,
      $key: 'emphasize',
    ),
    map-get(
      $map: $background-color,
      $key: 'main/default',
    ),
    map-get(
      $map: $interactive-color,
      $key: 'main/hover',
    ),
    map-get(
      $map: $interactive-color,
      $key: 'main/press',
    ),
    map-get(
      $map: $background-color,
      $key: 'main/default',
    ),
    map-get(
      $map: $text-icon-color,
      $key: 'white',
    )
  ),
  (
    'disable',
    map-get(
      $map: $text-icon-color,
      $key: 'disabled',
    ),
    $color-transparent,
    map-get(
      $map: $background-color,
      $key: 'main/disabled',
    ),
    map-get(
      $map: $background-color,
      $key: 'main/disabled',
    ),
    map-get(
      $map: $background-color,
      $key: 'main/disabled',
    ),
    map-get(
      $map: $background-color,
      $key: 'main/disabled',
    ),
    map-get(
      $map: $text-icon-color,
      $key: 'disabled',
    )
  ),
  (
    'plain-secondary',
    map-get(
      $map: $text-icon-color,
      $key: 'main',
    ),
    $color-transparent,
    $color-transparent,
    map-get(
      $map: $interactive-color,
      $key: 'secondary/hover',
    ),
    map-get(
      $map: $interactive-color,
      $key: 'secondary/press',
    ),
    $color-transparent,
    map-get(
      $map: $text-icon-color,
      $key: 'disabled',
    )
  ),
  (
    'plain-danger',
    map-get(
      $map: $text-icon-color,
      $key: 'danger',
    ),
    $color-transparent,
    $color-transparent,
    map-get(
      $map: $background-color,
      $key: 'danger/hover',
    ),
    map-get(
      $map: $background-color,
      $key: 'danger/press',
    ),
    $color-transparent,
    map-get(
      $map: $text-icon-color,
      $key: 'disabled',
    )
  ),
  (
    'plain-primary',
    map-get(
      $map: $text-icon-color,
      $key: 'emphasize',
    ),
    $color-transparent,
    $color-transparent,
    map-get(
      $map: $background-color,
      $key: 'emphasize/hover',
    ),
    map-get(
      $map: $background-color,
      $key: 'emphasize/press',
    ),
    $color-transparent,
    map-get(
      $map: $text-icon-color,
      $key: 'disabled',
    )
  )
);
@each $name, $color, $border-color, $bg-color, $bg-hover, $bg-pressed,
  $bg-disabled, $color-hover in $variants
{
  .variant-#{$name} {
    color: $color;
    border-color: $border-color;
    background-color: $bg-color;
    & span {
      color: $color;
    }
    .icon,
    .icon path {
      fill: $color;
    }
    &.disabled {
      pointer-events: none;
      background-color: $bg-disabled;
      border-color:  map-get($map: $text-icon-color, $key: 'disabled');
      @include text-icon-color('disabled');
      .icon,
      .icon path {
        fill: map-get($map: $text-icon-color, $key: 'disabled');
      }
      & span {
        @include text-icon-color('disabled');
      }
    }
    &:not(.disabled):hover {
      background-color: $bg-hover;
      & span {
        color: $color-hover;
      }
      > svg {
        color: $color-hover;
      }
    }
    &:not(.disabled):active,
    &:not(.disabled).activated {
      background-color: $bg-pressed;
    }
    &:not(.disabled).selected {
      @include border-color('emphasize');
      @include background-color('main/default');
      @include text-icon-color('emphasize');

      & span {
        @include text-icon-color('emphasize');
      }

      .start-icon,
      .start-icon path {
        fill: map-get($map: $base-color, $key: 'main');
      }
    }
  }
}

.full-width {
  width: 100%;
}
