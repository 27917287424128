@import '@core/uikit/build/src/styles/variables.scss';

.button_date_range {
  height: 44px;
  box-sizing: border-box;
  padding: 10px;
  background-color: map-get(
    $map: $background-color,
    $key: 'main/default'
  ) !important;

  &:hover {
    border: 2px solid;
    @include border-color('default');
    padding: 7px 15px;
  }
}

.input_date {
  width: 100%;

  & > div {
    height: 56px;
    position: relative;

    & > input {
      font-size: 16px !important;
      padding-right: 16px;
    }

    & > div {
      padding: 0;
    }

    & > div:last-child {
      padding-right: 8px;
    }
  }

  .icon_clear {
    padding: 0;

    & > span > svg > path {
      fill: $color-gunmetal;
    }
  }

  .divider {
    margin: 0px 10px;
    height: 24px;
  }

  .icon_date {
    padding: 0;

    & > span > svg > path {
      fill: $color-gunmetal;

      &:hover {
        fill: $color-persian-blue;
      }
    }
  }

  .icon_date_active {
    padding: 0;

    & > span > svg > path {
      fill: $color-persian-blue !important;
    }
  }
}

.input_date_disabled {
  & > div {
    height: 56px;
    background: $color-gray-base;
    border-color: $color-gray-darker;

    input {
      font-size: 16px !important;
    }
  }
}

.popover_daily {
  & > div:nth-child(3) {
    border-radius: 16px;
  }
}

.wrapper_popover_daily {
  width: 448px;
  height: auto;
  padding: 32px 32px 18px 32px;
  border: none;

  .text_year {
    font-size: 14px;
    margin-right: 4px;
  }

  .dropdown_month_year {
    & > div {
      border: none;
      padding: 0;

      &:hover,
      &:active {
        border: none;
      }

      & > div:first-child {
        padding: 0;

        & > span {
          font-family: Inter;
          font-weight: 500;
        }
      }
    }
  }

  .icon_arrow {
    display: flex;

    button {
      background: transparent;
      @include text-icon-color('default');
      padding: 0;

      &:hover {
        background: transparent;

        > svg {
          @include text-icon-color('emphasize');
        }
      }
    }

    .icon_arrow_disabled {
      svg {
        & > path {
          fill: map-get($map: $text-icon-color, $key: 'disabled');
        }
      }
    }
  }

  .wrapper_title_date {
    display: flex;
    flex-wrap: wrap;

    & > span {
      width: calc(100% / 7);
      text-align: center;
    }
  }

  .wrapper_item_date {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    & > span {
      width: calc(100% / 7);
      margin-bottom: 8px;
      text-align: center;
    }

    .item_date {
      width: 36px;
      height: 36px;
      border-radius: 200px;
      & span {
        @include text-icon-color('default');
      }
    }

    .lastChosen {
      button > span {
        @include text-icon-color('white');
      }
    }

    .item_date_base {
      background-color: transparent;

      &:hover {
        border: 1px solid;
        @include border-color('emphasize');
      }
    }

    .item_date_current {
      border: 1px solid;
      @include border-color('emphasize');
    }

    .item_date_active {
      @include interactive-color('background-color', 'main/default');
      border: none;

      & > span {
        color: $color-white;
      }
    }

    .item_date_disable {
      background-color: transparent;
      border: none;
      cursor: default;
    }

    .day_disable {
      color: #d2d2d2;
    }
  }
}

.yearPicker {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 6px;

  li {
    width: 90px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 8px;

    span {
      font-weight: 600;
      font-size: 14px;
      @include text-icon-color('default');
    }

    &:hover,
    &.active {
      @include border-color('emphasize');
    }
  }
}

.overlap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
}

.reset_icon {
  position: relative;
  z-index: 10;
  margin-right: 15px;
  visibility: hidden;
}

.reset_icon_active {
  visibility: visible;
}

.dateHover {
  @include background-color('emphasize/hover');
  @include text-icon-color('white');

  .item_date_active {
    background-color: transparent;
    border: none;

    & > span {
      @include text-icon-color('white');
    }
  }

  &:first-of-type {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
  }
  &:last-child {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
  }
}

.dateActive {
  @include interactive-color('background-color', 'main/default');
}

.mainBox {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid;
  @include border-color('default');
}

.dateRangeActions {
  width: 200px;
  padding: 0;
  margin: 0;
  border-right: 1px solid;
  @include border-color('default');

  li {
    list-style: none;
    padding: 7px 15px;
    cursor: pointer;
    @include text-icon-color('default');
    &:hover {
      @include interactive-color('background-color', 'main/default');
      @include text-icon-color('white');
    }
  }

  .active {
    @include interactive-color('background-color', 'main/default');
    @include text-icon-color('white');
  }
}

.firstChosen {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.lastChosen {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  @include interactive-color('background-color', 'main/default');
}

.applyBtn {
  padding: 15px;
  text-align: right;

  button {
    @include interactive-color('background-color', 'main/default');
    &:hover:not(.disabled) {
      @include interactive-color('background-color', 'main/hover');
    }
    &:active:not(.disabled) {
      @include interactive-color('background-color', 'main/press');
    }
  }
}
