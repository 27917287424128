@import '../../styles/variables.scss';

/**
  States: name, color.
*/
$root-statuses: (
  ('primary', map-get($map: $text-icon-color, $key: "disabled")),
  ('success', map-get($map: $text-icon-color, $key: "success")),
  ('warning', map-get($map: $text-icon-color, $key: "warning")),
  ('error', map-get($map: $text-icon-color, $key: "danger"))
);

.text-field {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @each $name, $textColor in $root-statuses {
    &:not(.disabled).status-label-#{$name} {
      color: $textColor;
      ::placeholder {
        color: $textColor;
      }
    }
  }

  .label {
    @include text-icon-color('secondary');
  }

  .optional,
  .input,
  .note {
    color: inherit;
  }

  .label,
  .optional {
    margin-bottom: spacing(2);
  }

  .note {
    margin-top: spacing(2);
  }
}

.label {
  display: inline-flex;
  flex: 1 1 auto;
  justify-content: flex-start;
}

.optional {
  display: inline-flex;
  justify-content: flex-end;
}

.input {
  flex-basis: 100%;
}

.note {
  flex-basis: 100%;
}
