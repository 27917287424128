.date-range {
  background: #f5f5f5;
  border: 1px solid #3896e3;
  & > svg > path {
    fill: #3896e3 !important;
  }
  & > span {
    color: #3896e3;
  }
}
