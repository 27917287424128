@import '../../../../../../styles/variables.scss';

$color-border-primary: map-get(
  $map: $border-color,
  $key: 'emphasize',
);
$color-text-primary: map-get(
  $map: $text-icon-color,
  $key: 'default',
);
$color-border: map-get(
  $map: $border-color,
  $key: 'default',
);

.timePicker {
  width: 170px;
}

.timeText {
  height: 40px;
  text-align: center;
  vertical-align: revert;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid $color-border;
  font-weight: 600;
  @include text-icon-color('default');
}

.timeSelectorBox {
  display: flex;
  height: 275px;
  overflow: hidden;
  transition: all 0.3s linear;
}

.timeSeletor {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  width: 50%;

  &:first-child {
    border-right: 1px solid $color-border;
  }

  .item {
    padding: 3px 3px 3px 16px;
    cursor: pointer;
    @include text-icon-color("default");

    &:hover {
      @include background-color('emphasize/hover');
    }

    &.itemActive {
      @include interactive-color('background-color', 'main/default');
      @include text-icon-color('white');
    }
  }
}
