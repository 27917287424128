@import '../../styles/variables.scss';

$font-family-base: 'Inter', 'Helvetica', 'Arial', sans-serif;
$letter-spacing: spacing(0.15);
$font-size-base: 1.4rem;
$line-height-base: 1.8rem;
$font-weight-base: 400;
$font-weight-bolder: $font-weight-bold;

.root {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
}

/*
  Variants list
  Order values: name, font size, line height, letter-spacing
*/
$variants: (
  (
    'head1',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-head1,
    $font-family-body,
    9.5,
    $letter-spacing
  ),
  (
    'head2',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-head2,
    $font-family-body,
    8,
    $letter-spacing
  ),
  (
    'head3',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-head3,
    $font-family-body,
    5.5,
    $letter-spacing
  ),
  (
    'head4',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-head4,
    $font-family-body,
    5.5,
    $letter-spacing
  ),
  (
    'body1',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-body1,
    $font-family-body,
    6,
    0
  ),
  (
    'body2',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-body2,
    $font-family-body,
    4.5,
    0
  ),
  (
    'subtitle1',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-subtitle1,
    $font-family-body,
    6,
    0.5px
  ),
  (
    'subtitle2',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-subtitle2,
    $font-family-body,
    5,
    0.75px
  ),
  (
    'caption',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-caption,
    $font-family-body,
    4,
    0
  ),
  (
    'button1',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-button1,
    $font-family-body,
    4.5,
    $letter-spacing
  ),
  (
    'button2',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-button2,
    $font-family-body,
    5,
    $letter-spacing
  ),
  (
    'buttonLink',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-buttonLink,
    $font-family-body,
    5,
    0.5px
  ),
  (
    'descriptionHeader',
    map-get(
      $map: $text-icon-color,
      $key: 'default',
    ),
    $font-size-caption,
    $font-family-body,
    5,
    0.5px
  )
);

@each $name, $color, $fontSize, $font-family-body, $line-height,
  $spacing in $variants
{
  .variant-#{$name} {
    font-family: $font-family-body;
    color: $color;
    font-size: $fontSize;
    line-height: spacing($line-height);
    letter-spacing: $spacing;
  }
}

.variant-inherit {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

/*
  Font weights list
  Order values: name, font-weight
*/
$weights: (
  ('light', $font-weight-light),
  ('regular', $font-weight-regular),
  ('medium', $font-weight-medium),
  ('bold', $font-weight-bold)
);

@each $name, $weight in $weights {
  .weight-#{$name} {
    font-weight: $weight;
  }
}

.weight-inherit {
  font-weight: inherit;
}

/*
  Types list
  Order values: name, color
*/
$types: (
  (
    'primary'
      map-get(
        $map: $text-icon-color,
        $key: 'emphasize',
      )
  ),
  (
    'secondary'
      map-get(
        $map: $text-icon-color,
        $key: 'secondary',
      )
  ),
  (
    'default'
      map-get(
        $map: $text-icon-color,
        $key: 'default',
      )
  ),
  (
    'titleDefault'
      map-get(
        $map: $text-icon-color,
        $key: 'default',
      )
  ),
  (
    'text'
      map-get(
        $map: $text-icon-color,
        $key: 'default',
      )
  ),
  (
    'white',
    map-get(
      $map: $text-icon-color,
      $key: 'white',
    )
  ),
  (
    'textButton'
      map-get(
        $map: $text-icon-color,
        $key: 'white',
      )
  ),
  (
    'disable'
      map-get(
        $map: $text-icon-color,
        $key: 'disabled',
      )
  ),
  (
    'error'
      map-get(
        $map: $text-icon-color,
        $key: 'danger',
      )
  ),
  (
    'invert'
      map-get(
        $map: $text-icon-color,
        $key: 'white',
      )
  ),
  (
    'link'
      map-get(
        $map: $text-icon-color,
        $key: 'link',
      )
  ),
  (
    'sub'
      map-get(
        $map: $text-icon-color,
        $key: 'secondary',
      )
  ),
  (
    'titleSub'
      map-get(
        $map: $text-icon-color,
        $key: 'secondary',
      )
  ),
  (
    'textSub'
      map-get(
        $map: $text-icon-color,
        $key: 'secondary',
      )
  ),
  (
    'success'
      map-get(
        $map: $text-icon-color,
        $key: 'success',
      )
  ),
  (
    'warning'
      map-get(
        $map: $text-icon-color,
        $key: 'warning',
      )
  ),
  (
    'brandLighter'
      map-get(
        $map: $background-color,
        $key: 'success/hover',
      )
  ),
  (
    'placeholder'
      map-get(
        $map: $text-icon-color,
        $key: 'disabled',
      )
  ),
  (
    'amount'
      map-get(
        $map: $text-icon-color,
        $key: 'emphasize',
      )
  ),
  ('inherit' inherit)
);

@each $name, $color in $types {
  .type-#{$name} {
    color: $color;
  }
}

.type-link {
  cursor: pointer;
}

.type-strikethrough {
  color: $color-disabled;
  text-decoration: line-through;
}

.type-inherit {
  color: inherit;
  text-decoration: inherit;
}

.gutter-bottom {
  margin-bottom: 0.35em;
}

.paragraph {
  margin-bottom: spacing(4);
}

.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-1 {
  -webkit-line-clamp: 1;
}

.truncate-2 {
  -webkit-line-clamp: 2;
}

.truncate-3 {
  -webkit-line-clamp: 3;
}

.truncate-4 {
  -webkit-line-clamp: 4;
}

.truncate-5 {
  -webkit-line-clamp: 5;
}
