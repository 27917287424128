$font-size-head1: 32px;
$font-size-head2: 24px;
$font-size-head3: 20px;
$font-size-head4: 16px;
$font-size-body1: 16px;
$font-size-body2: 14px;
$font-size-subtitle1: 16px;
$font-size-subtitle2: 14px;
$font-size-caption: 12px;
$font-size-button1: 16px;
$font-size-button2: 14px;
$font-size-buttonLink: 16px;

$font-weight-light: lighter;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 600;

$font-family-head: 'Kanit';
$font-family-body: 'Inter';
