.sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 7px;
  cursor: pointer;
}

.arrowIcon {
  font-size: 30px;
}

.headerAlignReverse {
  flex-direction: row-reverse;
}
