.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);

  .number {
    font-size: 100px;
    font-weight: bold;
    line-height: 100px;
    color: #606060;
  }

  .text {
    color: #606060;
  }
}
