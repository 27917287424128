.main {
  position: fixed;
  bottom: 100px;
  right: 15px;
  padding: 7px 15px;
  background-color: #fff;
  color: #031352;
  cursor: pointer;
  box-shadow: 0px 8px 16px rgb(3 19 82 / 16%);
  z-index: 9800;
}