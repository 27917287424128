@import '../../styles/variables.scss';

.menuShow {
  white-space: nowrap;
  transition: all 0.3s linear;
  background-color: #fff !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;

  &:hover {
    border-color: map-get($map: $border-color, $key: 'emphasize') !important;
  }
}

.formDropdown {
  margin-top: 45px;
}

.divider {
  margin: 7px 0;
}

@media (max-width: 1650px) {
  .languageName {
    display: none;
  }
}
