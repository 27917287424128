@import '../../styles/variables.scss';

$icon-color: map-get($map: $text-icon-color, $key: 'emphasize');
$icon-disable-color: map-get($map: $text-icon-color, $key: 'disabled');

.root {
  cursor: pointer;
  display: inline-flex;
  position: relative;
  align-items: center;
  height: 24px;
  transition: all 0.25s ease;

  &.disabled {
    // opacity: 0.7;
    pointer-events: none;
    color: $icon-disable-color;
  }

  &:not(.disabled):not(.intermediate).checked {
    .icon {
      color: $icon-color;
    }
  }

  &.disabled {
    .icon {
      color: $icon-disable-color;
    }
  }

  .icon {
    color: $icon-color;
  }
}

.content {
  margin-left: spacing(2);
}

.input {
  display: none;
}
