@import '../../styles/variables.scss';

$font-family-base: 'Inter', 'Helvetica', 'Arial', sans-serif;
$letter-spacing: 0.75px;
$font-size-base: 14px;
$line-height-base: 22px;
$font-weight-base: 400;
$font-weight-bolder: $font-weight-bold;

.root {
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
}

/*
  Variants list
  Order values: name, font size, font weight, line height, letter spacing
*/
$variants: (
  ('display', $font-size-48, $font-weight-regular, 56px, -0.5px),
  ('headline', $font-size-32, $font-weight-regular, 40px, -0.25px),
  ('title', $font-size-24, $font-weight-medium, 32px, 0px),
  ('headingAndLargeButton', $font-size-20, $font-weight-medium, 28px, 0.25px),
  ('buttonHighLevel', $font-size-16, $font-weight-bold, 24px, 0.5px),
  ('buttonLowLevel', $font-size-14, $font-weight-bold, 22px, 0.75px),
  ('bodyHighLevel', $font-size-16, $font-weight-regular, 24px, 0.5px),
  ('bodyLowLevel', $font-size-14, $font-weight-regular, 22px, 0.75px),
  ('supportTextEmphasize', $font-size-12, $font-weight-emphasize, 20px, 1px),
  ('supportTextNormal', $font-size-12, $font-weight-medium, 20px, 1px)
);

@each $name, $font-size, $font-weight, $line-height, $spacing in $variants {
  .variant-#{$name} {
    font-family: 'Inter';
    font-size: $font-size;
    font-weight: $font-weight;
    @include text-icon-color('default');
    line-height: $line-height;
    letter-spacing: $spacing;
  }
}

.variant-inherit {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

/*
  Font weights list
  Order values: name, font-weight
*/
$weights: (
  ('light', $font-weight-light),
  ('regular', $font-weight-regular),
  ('medium', $font-weight-medium),
  ('bold', $font-weight-bold),
  ('emphasize', $font-weight-emphasize)
);

@each $name, $weight in $weights {
  .weight-#{$name} {
    font-weight: $weight;
  }
}

.weight-inherit {
  font-weight: inherit;
}

/*
  Types list
  Order values: name, color
*/
$types: (
  (
    'primary'
      map-get(
        $map: $text-icon-color,
        $key: 'default',
      )
  ),
  (
    'secondary'
      map-get(
        $map: $text-icon-color,
        $key: 'secondary',
      )
  ),
  (
    'disabled'
      map-get(
        $map: $text-icon-color,
        $key: 'disabled',
      )
  ),
  (
    'success'
      map-get(
        $map: $text-icon-color,
        $key: 'success',
      )
  ),
  (
    'warning'
      map-get(
        $map: $text-icon-color,
        $key: 'warning',
      )
  ),
  (
    'danger'
      map-get(
        $map: $text-icon-color,
        $key: 'danger',
      )
  ),
  (
    'emphasize'
      map-get(
        $map: $text-icon-color,
        $key: 'emphasize',
      )
  ),
  (
    'white',
    map-get(
      $map: $text-icon-color,
      $key: 'white',
    )
  ),
  (
    'link'
      map-get(
        $map: $text-icon-color,
        $key: 'link',
      )
  ),
  ('inherit' inherit)
);

@each $name, $color in $types {
  .type-#{$name} {
    color: $color;
  }
}

.type-link {
  cursor: pointer;
}

.type-inherit {
  color: inherit;
  text-decoration: inherit;
}

.gutter-bottom {
  margin-bottom: 0.35em;
}

.paragraph {
  margin-bottom: spacing(4);
}

.truncate {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncate-1 {
  -webkit-line-clamp: 1;
}

.truncate-2 {
  -webkit-line-clamp: 2;
}

.truncate-3 {
  -webkit-line-clamp: 3;
}

.truncate-4 {
  -webkit-line-clamp: 4;
}

.truncate-5 {
  -webkit-line-clamp: 5;
}
