@import '../../../../../styles/variables.scss';

$color-border-primary: map-get(
  $map: $border-color,
  $key: 'emphasize',
);
$color-text-primary: map-get(
  $map: $text-icon-color,
  $key: 'default',
);
$color-border: map-get(
  $map: $border-color,
  $key: 'default',
);
$color-text-disabled: map-get(
  $map: $text-icon-color,
  $key: 'disabled',
);

.input_date {
  width: 100%;

  & > div {
    height: 56px;
    position: relative;

    & > input {
      font-size: 16px !important;
      padding-right: 16px;
    }

    & > div {
      padding: 0;
    }

    & > div:last-child {
      padding-right: 8px;
    }
  }

  .icon_clear {
    padding: 0;

    & > span > svg > path {
      fill: $color-gunmetal;
    }
  }

  .divider {
    margin: 0px 10px;
    height: 24px;
  }

  .icon_date {
    padding: 0;

    & > span > svg > path {
      fill: $color-gunmetal;

      &:hover {
        fill: $color-border-primary;
      }
    }
  }

  .icon_date_active {
    padding: 0;

    & > span > svg > path {
      fill: $color-border-primary !important;
    }
  }
}

.input_date_disabled {
  & > div {
    height: 56px;
    background: $color-gray-base;
    border-color: $color-gray-darker;

    input {
      font-size: 16px !important;
    }
  }
}

.popover_daily {
  & > div:nth-child(3) {
    border-radius: 16px;
  }
}

.wrapper_popover_daily {
  height: auto;
  border-radius: 16px;
  border: 1px solid $color-gray-darker;
  display: flex;
  flex-direction: column;
  padding: 0;

  .datetimePicker {
    display: flex;
    flex-direction: row;

    .datePicker {
      width: 412px;
      border-right: 1px solid $color-border;
    }
  }

  .datePickerHeader {
    height: 40px;
    text-align: center;
    vertical-align: revert;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    align-items: center;
    border-bottom: 1px solid $color-border;
  }

  .datePickerBody {
    padding: 20px 16px 0;
  }

  .text_year {
    font-size: 14px;
    margin-right: 4px;
    cursor: pointer;
  }

  .dropdown_month_year {
    & > div {
      border: none;
      padding: 0;

      &:hover,
      &:active {
        border: none;
      }

      & > div:first-child {
        padding: 0;

        & > span {
          font-family: Inter;
          font-weight: 500;
        }
      }
    }
  }

  .icon_arrow {
    display: flex;

    button {
      background: transparent;
      padding: 0;
      @include text-icon-color('default');

      &:hover {
        background: transparent;

        > svg {
          @include text-icon-color('default');
        }
      }
    }

    .icon_arrow_disabled {
      svg {
        & > path {
          fill: $color-gray-darkest;
        }
      }
    }
  }

  .wrapper_title_date {
    display: flex;
    flex-wrap: wrap;

    & > span {
      width: calc(100% / 7);
      text-align: center;
    }
  }

  .wrapper_item_date {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    & > span {
      width: calc(100% / 7);
      margin-bottom: 8px;
      text-align: center;
    }

    .item_date {
      width: 36px;
      height: 36px;
      border-radius: 200px;

      span {
        @include text-icon-color('default');
      }
    }

    .item_date_base {
      background-color: transparent;

      &:hover {
        border: 1px solid $color-border-primary;
      }
    }

    .item_date_current {
      border: 1px solid $color-border-primary;
    }

    .item_date_active {
      background-color: $color-border-primary;
      border: none;

      & > span {
        color: $color-white;
      }
    }

    .item_date_disable {
      background-color: transparent;
      border: none;
      cursor: default;
    }

    .day_disable {
      color: $color-text-disabled !important;
    }
  }

  .year {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 6px;

    li {
      width: 90px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid transparent;
      cursor: pointer;
      border-radius: 8px;
      margin-bottom: 8px;

      span {
        font-size: 14px;
        @include text-icon-color('default');
      }

      &:hover,
      &.active {
        border-color: $color-border-primary;
      }
    }
  }
}

.overlap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
}

.reset_icon {
  position: relative;
  z-index: 10;
  margin-right: 15px;
  visibility: hidden;
}

.reset_icon_active {
  visibility: visible;
}

.inputDateActive {
  > div:not(.disabled) {
    @include border-color('emphasize');
    border-width: 2px;
  }
}

.actions {
  border-top: 1px solid $color-border;
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px;
}
