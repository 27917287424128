@import '../../styles/variables.scss';

.table {
  display: table;
  padding: 1px;
  width: 100%;
  background-color: $color-white;
  border-spacing: 0;
  border-collapse: collapse;
  // border: 1px solid transparent;
  border-radius: $radius-bold;
  overflow: hidden;
  line-height: 1.33;
  // Workaround ie11: https://stackoverflow.com/questions/25201854/internet-explorer-ignores-width-when-use-css-table
  table-layout: fixed;
}
