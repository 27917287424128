@import '../../styles/variables.scss';

.wrapperItemCard {
  @include background-color('main/default');
  padding: 24px;
  border: 1px solid map-get($map: $border-color, $key: 'default');
  border-radius: 16px;
  width: 100%;
  height: 100%;
}

.customTextField {
  margin-top: 16px;
  input {
    font-size: 16px !important;
  }
}

.wrapperTextStyle {
  border: 1px solid map-get($map: $border-color, $key: 'default');
  border-radius: 8px;
}

.textStyle {}

.titleStyle {
  height: 34px;
}
