@import '../../../../styles/variables.scss';

.errorNotDisable {
  background-color: $color-red-lightest;
  color: $color-red-base !important; // Override MUI Autocomplete inputRoot
  border-color: $color-red-base !important; // Override MUI Autocomplete inputRoot
  padding: 1px;
}

.autoComplete {
  .inputRoot {
    min-height: 60px;
    border: 1px solid map-get($border-color, 'default');
    padding: 1px 56px 1px 1px;
    &:hover,
    &:focus-within {
      border: 2px solid map-get($border-color, 'emphasize');
      padding: 0px 55px 0px 0px;
    }
  }
}