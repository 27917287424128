@import '../../styles/variables.scss';

$color-border: map-get(
  $map: $border-color,
  $key: 'default',
);
$color-space-bg-active: map-get(
  $map: $background-color,
  $key: 'emphasize/default',
);
$color-space-bg-hover: map-get(
  $map: $background-color,
  $key: 'emphasize/hover',
);
$color-space-bg-press: map-get(
  $map: $background-color,
  $key: 'emphasize/press',
);

$header-height: 72px;
$spaces-box-width: 72px;

.spaces {
  @include background-color('main/default');
  z-index: 100;
  width: $spaces-box-width;
  border-right: 1px solid $color-border;
  height: calc(100vh - #{$header-height});
  position: fixed;
  top: $header-height;
  left: 0;
  padding-top: 16px;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      display: flex;
      justify-content: center;
      margin-bottom: 12px;

      > a {
        border-radius: 50%;
        @include text-icon-color('default');
        transition: all 0.3s linear;
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background: $color-space-bg-hover;
        }
        &:active {
          background: $color-space-bg-press;
        }
      }

      &.active {
        pointer-events: none;
        > a {
          pointer-events: none;
          background: $color-space-bg-active;
          @include text-icon-color('emphasize');
        }
      }
    }
  }
}

.hideSpaceList {
  top: 0;
  height: 0;
}
