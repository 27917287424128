@import '../../styles/variables.scss';

.wrapper-input {
  height: 44px;
}

.reset-wrapper {
  display: flex;
  align-items: center;
}

.divider {
  border: 1px solid map-get($map: $border-color, $key: 'emphasize') !important;
  height: 24px !important;
}

.placeholder-search {
  ::placeholder {
    color: #babec3 !important;
  }
}

.text {
  & > input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px !important;
  }
}
