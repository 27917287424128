@import '@core/uikit/build/src/styles/variables.scss';

$color-border: map-get(
  $map: $border-color,
  $key: 'default',
);
$color-space-bg-active: map-get(
  $map: $background-color,
  $key: 'emphasize/default',
);
$color-space-bg-hover: map-get(
  $map: $background-color,
  $key: 'emphasize/hover',
);
$color-space-bg-press: map-get(
  $map: $background-color,
  $key: 'emphasize/press',
);

$header-height: 72px;
$spaces-box-width: 72px;
$padding-left-right: 24px;
$menu-box-width: 248px;

.divider {
  margin: 0px 24px !important;
  background-color: #e0e0e0 !important;
  height: 24px !important;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid $color-border;
  background-color: #fff;

  .logoBox {
    height: $header-height;
    display: flex;

    .collapse {
      border-right: 1px solid $color-border;
      width: $spaces-box-width;
      @include flex-center;
      cursor: pointer;
    }

    .logo {
      border-right: 1px solid $color-border;
      width: $menu-box-width;
      display: flex;
      align-items: center;
      padding-left: $padding-left-right;
    }
  }

  .other {
    display: flex;
    align-items: center;
    margin-right: $padding-left-right;
  }
}

.body {
  width: 100%;

  .sidebar {
    width: 0;
    opacity: 0;
    border-right: 1px solid $color-border;
    height: calc(100vh - #{$header-height});
    position: fixed;
    top: $header-height;
    left: $spaces-box-width;
    transition: all 0.3s linear;
    padding-top: 16px;
    visibility: hidden;

    &.sidebarShow {
      width: $menu-box-width;
      opacity: 1;
      visibility: visible;
    }

    .spaceName {
      width: fit-content;
      font-weight: 500;
      line-height: 32px;
      padding: 0 16px;
      margin-bottom: 16px;
      font-size: 20px;
      letter-spacing: 0.25px;

      span {
        max-width: 200px;
        @include text-ellipsis;
        display: block;
        @include text-icon-color('default');
        border-bottom: 2px solid map-get($map: $base-color, $key: 'main');
      }
    }

    &.noSpaces {
      width: calc(248px + 72px);
      left: 0;

      .spaceName {
        span {
          max-width: calc(200px + 86px);
        }
      }
    }

    .menu {
      max-height: calc(100vh - #{$header-height} - 80px);
      padding: 0 16px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .content {
    padding-top: $header-height;
    margin-left: calc(#{$spaces-box-width});
    transition: all 0.3s linear;

    &.contentShow {
      margin-left: calc(#{$menu-box-width} + #{$spaces-box-width});
    }

    &.contentShowFullWidth {
      margin-left: 0;
    }
  }
}

.tooltip {
  background-color: #fff;
  color: #000;
  font-size: 14px;
  border: 1px solid #e0e0e0;
  padding: 8px 16px;
  filter: drop-shadow(0px -4px 16px rgba(3, 19, 82, 0.16));
}

.tooltipArrow {
  &::before {
    background-color: #fff;
    border: 1px solid #e0e0e0;
  }
}
