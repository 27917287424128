@import '../../styles/variables.scss';

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    @include background-color('main/default');
  }

  /* Show the indicator (dot/circle) when checked */
  & input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  & .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    @include interactive-color('background-color', 'main/default');
  }
}
/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  border: 2px solid;
  @include border-color('emphasize');
  border-radius: 50%;

  /* Create the indicator (the dot/circle - hidden when not checked) */
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
}
