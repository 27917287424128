@import '../../styles/variables.scss';

/**
   Sizes: name, padding-vertical, padding-horizontal.
*/
$sizes: (
  ('xl', 4, 6),
  ('lg', 3, 4),
  ('md', 2, 4),
  ('sm', 2, 2),
  ('xs', 1.5, 2)
);

.select {
  line-height: spacing(4.5);
  width: 100%;
  min-width: max-content;
  cursor: pointer;
}

@each $name, $vertical, $horizontal in $sizes {
  .select-size-#{$name} {
    padding: spacing($vertical) spacing($horizontal);
    padding-right: 0;
    line-height: 1.29;
  }
}

.disabled {
  pointer-events: none;
}
.placeholder {
  color: $color-purple-darker;
  transition: color 0.1s ease;
  &:hover {
    color: $color-purple-darker;
  }
}

.icon {
  fill: $color-blue-dark;
}

/**
  States: name, icon size.
*/
$icon-sizes: (('lg', 6), ('md', 6), ('sm', 4), ('xs', 3));
@each $name, $icon-size in $icon-sizes {
  .addon-size-#{$name} {
    .icon {
      width: spacing($icon-size);
      height: spacing($icon-size);
    }
  }
}

$label-sizes: (
  ('xl', $font-size-body1, spacing(6)),
  ('lg', $font-size-body1, spacing(6)),
  ('md', $font-size-body1, spacing(6)),
  ('sm', $font-size-body2, spacing(4)),
  // ('xs', $font-size-tiny, spacing(3))
);
@each $name, $size, $line-height in $label-sizes {
  .label-size-#{$name} {
    font-size: $size;
    line-height: $line-height;
  }
}

.input.is-open {
  @include background-color('main/default');
  border: 2px solid;
  @include border-color('emphasize');
  padding: 0;

  b,
  span {
    min-height: 24px;
    @include text-icon-color('emphasize');
  }

  path {
    fill: map-get($map: $base-color, $key: 'main');
  }
}

.input.is-selected {
  @include background-color('main/default');
  border: 1px solid;
  border-color: map-get($map: $border-color, $key: 'emphasize') !important;
  padding: 1px;
  .icon,
  .icon svg {
    fill: map-get($map: $base-color, $key: 'main');
  }
  span {
    @include text-icon-color('emphasize');
  }
}

// .input:not(.is-open) {
//   .label-text {
//     color: $color-blue-dark;
//   }
// }
