.loadingBox {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > svg {
    margin-bottom: 15px;
    font-size: 30px;
  }
}

.progress {
  width: 300px;
  height: 7px;
  background-color: #e9e9e9;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    background-color: #7462E1;
    animation: loading 1.5s linear infinite;
  }

  @keyframes loading {
    0% {
      transform: translateX(-50px);
    }
    100% {
      transform: translateX(300px);
    }
  }
}